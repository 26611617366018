define("wavelengthchatt/components/under-construction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container">
  	<div class="col">
  		<img class="img-fluid" src="assets/images/WavelengthLogo.png"/>
  	</div>
  	<div class="col">
  		<h1 class="text-center">Coming Soon</h1>
  	</div>
  </div>
  {{yield}}
  */
  {
    "id": "rN5MlBgT",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"col\"],[12],[1,\"\\n\\t\\t\"],[10,\"img\"],[14,0,\"img-fluid\"],[14,\"src\",\"assets/images/WavelengthLogo.png\"],[12],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"col\"],[12],[1,\"\\n\\t\\t\"],[10,\"h1\"],[14,0,\"text-center\"],[12],[1,\"Coming Soon\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "wavelengthchatt/components/under-construction.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});